h1 {
  text-align: center;
  font-family: "Oswald", sans-serif;
  padding-top: 1.5rem;
}

.popUpButton {
  right: 12rem;
  top: 3rem;
  position: fixed;
}
.form-container {
  width: 80vw;
  margin: auto;
  text-align: center;
}

.form-label {
  max-width: 20vw;
  margin-left: 10vw;
  font-family: "Oswald", sans-serif;
  font-size: 1.3rem;
  color: #204051;
}

.form-wrapper {
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.form-wrapper  button {
  width: 20vw;
  margin: auto;
}
