body, html {
    height: 100%; 
    background-color: grey;
}
table {
    max-width: 80vw;
    margin: auto;
    margin-bottom: 10vh;
}
p {
    max-width: 80vw;
    margin: auto;
    margin-bottom: 1vh;
}
.table-headings {

    list-style-type: none;
    justify-content: center;
    margin-top: 1rem;
    margin-left: 5rem;
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
    font-size: 1.1rem;
}

.table-headings th {
    padding: 0.5rem 2rem;
    border: solid;

    text-align: center;
    margin-bottom: -1rem;
}

.table-headings th:not(:nth-child(9)) {
    background-color: #cceabb;   
}