.portfolio-container {
    text-align: center;
    max-width: 70%;
    margin-left: 13rem;
}

.deleteStock {
    margin-right: 7rem;
    cursor: pointer;
}

.deleteStock:hover {
    color: #d54062;

}

.stockChart {
    cursor: pointer;
}

.stockChart:hover {
    color: #00b7c2;
}

.returnPositive a{
    color: #228B22 !important;
    font-weight: 900;
}

.returnPositive a:hover{
    color: #228B22;
}

.returnNegative a{
    color:#FF0000 !important;
}

.returnNegative a:hover{
    color: #FF0000
    ;
}

.sub-table-headings {

    list-style-type: none;
    justify-content: center;
    margin-top: 1rem;
    margin-left: 5rem;
    font-family: 'Roboto Slab', serif;
    font-weight: 500;
}

.sub-table-headings td {
    padding: 0.5rem 2rem;
    border: solid;

    text-align: center;
    margin-bottom: -1rem;
}

.sub-table-headings td:not(:nth-child(9)) {
    background-color: #ffd8a6;   
}