html, body { 
    height:100%; 
    margin:0; 
}

.footer {
    bottom:0;
    width:100%;
    color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    background-color: #363636;
}

.nameLink a {
    color: #FF69B4;
    text-decoration: none;
    font-weight: 900;
}